var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { CloseButton } from 'react-bootstrap';
import '../../js/redux/resources/flashes';
import './b-flashes.scss';
var Flash = function (_a) {
    var flash = _a.flash, transitionGroupProps = __rest(_a, ["flash"]);
    var id = flash.id, text = flash.text, type = flash.type;
    var onClose = useCallback(function () { return app.flash.hide(id); }, [id]);
    var innerHtml = useMemo(function () { return ({ __html: text }); }, [text]);
    var itemRef = useRef(null);
    return (React.createElement(CSSTransition, __assign({ nodeRef: itemRef, timeout: 350, classNames: "flash" }, transitionGroupProps),
        React.createElement("div", { className: classNames('flash', 'd-flex', type), ref: itemRef },
            React.createElement("div", { dangerouslySetInnerHTML: innerHtml }),
            React.createElement(CloseButton, { variant: "white", onClick: onClose }))));
};
function Flashes(_a) {
    var flashes = _a.flashes, predefinedFlashes = _a.predefinedFlashes;
    useEffect(function () {
        if (predefinedFlashes.length > 0) {
            app.flash.auto(predefinedFlashes);
        }
    }, []);
    return (React.createElement("div", { className: "b-flashes" },
        React.createElement(TransitionGroup, { className: "d-flex flex-column align-items-end" }, flashes.map(function (flash) { return React.createElement(Flash, { key: flash.id, flash: flash }); }))));
}
export default app.redux.connectToRedux(Flashes, ['flashes']);
